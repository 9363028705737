<template>
    <div class="card" v-show="show">
        <div class="card-body p-0">
            <div class="text-center px-5 mt-15">
                <img src="/images/unauthorized-access.png" alt="" class="mh-125px">
            </div>

            <div class="card-px text-center pb-20 my-10 mb-0">
                <h2 class="fs-2x fw-bolder mb-3">{{ $t('pages.protectArea.title') }}</h2>
                <p class="text-gray-400 fs-5 fw-bold mb-13">{{ $t('pages.protectArea.description') }}</p>

                <button type="button" class="btn btn-primary er fs-6 px-8 py-4 w-100 w-sm-auto" @click="$router.back();">
                    <span class="svg-icon svg-icon-2">
                        <inline-svg src="/media/icons/duotune/arrows/arr021.svg"/>
                    </span>
                    {{ $t("btn.back") }}
                </button>

                <button type="button" class="btn btn-danger er fs-6 px-8 py-4 ms-0 ms-sm-4 mt-3 mt-sm-0 collapsible rotate collapsed w-100 w-sm-auto justify-content-center" role="button" data-bs-toggle="collapse" data-bs-target="#forbidden-endpoints" aria-expanded="true" aria-controls="forbidden-endpoints">
                    <span class="me-2">{{ $t('pages.protectArea.forbiddenEndpoints') }}</span>

                    <div class="rotate-90">
                        <span class="svg-icon svg-icon-2 me-0">
                            <inline-svg src="/media/icons/duotune/arrows/arr024.svg"/>
                        </span>
                    </div>
                </button>

                <div id="forbidden-endpoints" class="collapse mt-9">
                    <div class="m-auto w-100 max-w-600px">
                        <div class="d-flex justify-content-between align-items-center" :class="[forbiddenEndpointIndex !== 0 && 'mt-2']" v-for="(forbiddenEndpoint, forbiddenEndpointIndex) in forbiddenEndpoints" :key="forbiddenEndpointIndex">
                            <div class="d-flex align-items-center">
                                <span class="svg-icon svg-icon-1 svg-icon-danger d-none d-sm-inline-block">
                                    <inline-svg src="/media/icons/duotune/arrows/arr015.svg"/>
                                </span>

                                <span class="ms-2 fw-bold fs-5 text-gray-500 text-start">
                                    {{ getEndpointPath(forbiddenEndpoint) }}
                                </span>
                            </div>

                            <div>
                                <span class="badge-light-danger badge">{{ $t('endpointActions.' + forbiddenEndpoint.method.toLowerCase()) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    name: "index",
    data() {
        return {
            endpointPathPattern: "admin/api/v1/%s"
        }
    },
    computed: {
        isAccess() {
            return this.$store.getters.isAccess;
        },
        forbiddenEndpoints() {
            return this.$store.getters.forbiddenEndpoints;
        },
        endpointList() {
            return this.$store.state.system.admin.group.endpoint.table.data;
        }
    },
    mounted() {
        this.$store.dispatch('system/admin/group/endpoint/get');
    },
    methods: {
        getEndpointPath(forbiddenEndpoint) {
            let forbiddenEndpointUrl = this.sprintf(this.endpointPathPattern, [forbiddenEndpoint.url]);
            let forbiddenUrlRegexResult = new RegExp('(.*)\\/(.*)$', 'g').exec(forbiddenEndpointUrl);

            let endpoint = this.endpointList.filter((endpoint) => {
                let pathRegexResult = new RegExp('(.*)\\/(.*)$', 'g').exec(endpoint.path);

                if (forbiddenUrlRegexResult[0] == pathRegexResult[0]) {
                    return true;
                } else if (forbiddenUrlRegexResult[1] == pathRegexResult[1]) {
                    if (forbiddenEndpointUrl[2] == pathRegexResult[2]) {
                        return true;
                    } else {
                        if (new RegExp('{(.*)}$', 'g').exec(pathRegexResult[2])) {
                            return true;
                        }
                    }
                }

                return false;
            });

            if (endpoint.length > 1) {
                endpoint = endpoint.filter((endpoint) => {
                    return forbiddenEndpointUrl == endpoint.path;
                })
            }

            return endpoint.length == 1 ? endpoint[0].path : forbiddenEndpoint.url;
        },
        removeModal() {
            this.removeModalBackdrop();
            let body = document.querySelector("body");
            body.classList.remove('modal-open')
            body.style.overflow = '';
            body.style.paddingRight = '';
        }
    },
    watch: {
        show(value, oldValue) {
            if (value) {
                this.$nextTick(() => {
                    this.removeModal();
                })
            }
        }
    }
}
</script>

<style scoped>

</style>